
import { Vue, Component } from 'vue-property-decorator';
import Measurements from '@/ui/views/Project/Measurements.vue';
import { Action, Getter, State } from 'vuex-class';
import { mapMeasurementsList } from '@/utils/utilsFunctions';

/**
 * Component that shows Variables settings tab
 */
@Component({
  components: {
    Measurements,
  },
})
export default class Variables extends Vue {
  @Getter('measurements/measurements') measurements!: any;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @State('measurements') measurementsState!: any;

  get filteredMeasurements() {
    return this.measurementsFilter
      ? this.measurementsList
        .filter((variable: any) => Object.values(variable).join().toLowerCase().includes(this.measurementsFilter.toLowerCase()))
      : this.measurementsList;
  }

  /**
   * Getter map measurements list,
   * remove all measurements which name length = 0,
   * set value rounded if it has to many numbers after comma,
   * if the value is a string and is longer than 30 characters, truncates it
   */
  get measurementsList() {
    return mapMeasurementsList(this.measurements);
  }

  get measurementsFilter() {
    return this.measurementsState.measurementsFilter;
  }

  async created() {
    await this.fetchMeasurements(this.$route.params.id);
  }
}
